body {
  background-color: rgb(0 0 0 / 8%);
}

.background-header {
  background: #f8f9fa;
}

.p-dialog {
  max-width: 860px;
}

.container-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-tabmenu .p-tabmenu-nav {
  border-radius: 6px;
}

.p-password-input {
  width: 100%;
}

.buttons {
  gap: 10px;
  display: flex;
  justify-content: center;
}

.hidden-disabled {
  opacity: 1 !important;
}

.field {
  margin-bottom: 10px !important;
}

.field > label {
  margin-bottom: 1px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.field .p-inputtext,
.field .p-button {
  height: 35px;
  padding: 0.5rem 0.5rem;
}

label {
  margin-right: 4px;
}

/* .p-fieldset-legend {
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 15px;
} */

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  font-size: 13px !important;
  height: 10px;
}

.dashboard-agendamento {
  border: 1px solid #707070;
  border-radius: 15px;
  padding-left: 10px;
  color: #000000;
  font-weight: 500;
}

.p-tag.p-tag-secondary {
  background-color: #64748b;
  color: #ffffff;
}

.dashboard-agendamento-agendado {
  background-color: #3b82f6;
}

.dashboard-agendamento-em-atendimento {
  background-color: #f59e0b;
}

.dashboard-agendamento-finalizado {
  background-color: #22c55e;
}

.dashboard-agendamento-cancelado {
  background-color: #64748b;
}

.dashboard-movimentacao-entrada {
  background-color: #22c55e3a !important;
}

.dashboard-movimentacao-saida {
  background-color: #dc26263a !important;
}

.p-datatable-wrapper {
  border-radius: 5px;
  display: flex;
  border: 1px solid #dee2e6;
}

.icon-confirmacao-enviada {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
}

.icon-confirmacao-enviada .pi {
  font-size: 10px;
}

.p-selectbutton .p-button.p-highlight:hover,
.p-selectbutton .p-button.p-highlight {
  background: #3b82f6 !important;
  border-color: #3b82f6 !important;
  opacity: 1 !important;
  color: #ffffff;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-input-icon-left > .p-input-prefix,
.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type {
  color: #6c757d;
  left: 0.75rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background-color: transparent !important;
  color: #7d8c94 !important;
  border: 1px solid #7d8c94 !important;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #3b82f6 !important;
  border-color: #3b82f6 !important;
  opacity: 1 !important;
  color: #ffffff !important;
}

.fc .fc-daygrid-event,
.fc-direction-ltr .fc-daygrid-more-link {
  background-color: #3b82f6 !important;
  border-color: #3b82f6 !important;
  color: #ffffff !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #3b82f6, 0 1px 2px 0 rgb(0, 0, 0);
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #7d8c94, 0 1px 2px 0 rgb(0, 0, 0);
}


.p-inputnumber-input {
  min-width: 0px !important;
}

/* Landing Page Styles */
.landing-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero-section {
  padding: 3rem 0;
}

.hero-image {
  max-width: 100%;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.features-section,
.testimonials-section,
.cta-section {
  padding: 4rem 0;
}

.features-section .p-card,
.testimonials-section .p-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}

.features-section .p-card:hover,
.testimonials-section .p-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.text-primary {
  color: #3b82f6 !important;
}

@media screen and (max-width: 768px) {
  .hero-section {
    text-align: center;
  }
  
  .hero-section .flex {
    justify-content: center;
  }
}